import API from "@/api/index.js";

export default {
  // 直接查询
  directQuery: params => {
    return API.requestGet({ url: "direct/query", params });
  },
  blackQuery: params => {
    return API.requestGet({ url: "direct/checkUser", params });
  },
  // 查询列表
  getList: param => {
    return API.requestGet({
      url: "friend/getList",
      auth: true,
      params: { isDirectQuery: true, ...param }
    });
  },
  // 查询列表
  detail: id => {
    return API.requestGet({
      url: "friend/detail",
      auth: true,
      params: { id }
    });
  },
  // 查询列表
  superInfo: param => {
    return API.requestPost({
      url: "user/superInfo",
      auth: true,
      param
    });
  },

  // 查询列表
  deleteUser: id => {
    return API.requestGet({
      url: "friend/delete",
      auth: true,
      params: { id }
    });
  },
  // 查询列表
  blackUser: id => {
    return API.requestGet({
      url: "friend/black",
      auth: true,
      params: { id }
    });
  }
};
