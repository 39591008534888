<template>
  <!-- v-loading.fullscreen.lock="loading" -->
  <div class="risk user-box">
    <search-form @query="query" @search="searchList" ref="child"></search-form>
    <query-table :list="list" @view="openReport" @delete="deleteReport" @black="blackReport"></query-table>
    <!-- 分页管理 -->
    <el-pagination background :page-sizes="pageSizes" :page-size="10" :current-page="currentPage"
      layout="total, sizes, prev, pager, next" :total="page.allNum" @size-change="changeSize"
      @current-change="changePage"></el-pagination>
  </div>
</template>

<script>
import searchForm from './components/search-form';
import queryTable from './components/query-table';

import service from './api';
import pagination from '@/mixins/pagination';
import getReports from '@/mixins/getReports';
import { Message } from "element-ui";

export default {
  mixins: [pagination, getReports],
  data() {
    return {
      list: [],
      page: {},
      queryType: 1
    };
  },
  components: {
    searchForm,
    queryTable
  },
  methods: {
    init() {
      let initParams = { pageNum: 1,queryType: this.queryType };
      return this.getList(initParams);
    },
    // 获取查询列表
    getList(params) {
      // 切换当前页码
      let { pageNum = 1,queryType = this.queryType } = params;
      this.currentPage = pageNum;

      return service.getList(params).then(res => {
        let { list = [], page = {} } = res;
        list.forEach((item, index) => {
          item.tempId = (params.pageNum - 1) * page.pageSize + index + 1;
        });
        this.list = list;
        this.page = page;
      });
    },
    deleteReport(data){
      this.$confirm('此操作将删除此用户信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        service.deleteUser(data.flId).then(res =>{
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.getList({ pageNum: this.currentPage, pageSize: this.pageSize,queryType: this.queryType });
        })
      }).catch((r) => {
        // console.log(r);
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    blackReport(data){
      this.$prompt('是否将此用户拉入黑名单, 是否继续?', '提示', {
        confirmButtonText: '拉黑',
        cancelButtonText: '取消',
        inputPattern: /(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)?/,
        inputErrorMessage: '请输入拉黑原因'
      }).then(({ value }) => {
        service.blackUser({id:data.flId,memo:value}).then(res =>{
          this.$message({
            type: 'success',
            message: '拉黑成功!'
          });
          this.getList({ pageNum: this.currentPage, pageSize: this.pageSize,queryType:this.queryType });
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消拉黑'
        });
      });
      // this.$confirm('是否将此用户拉入黑名单, 是否继续?', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(() => {

      // }).catch((r) => {
      //   // console.log(r);
      //   this.$message({
      //     type: 'info',
      //     message: '已取消删除'
      //   });
      // });
    },
    // 查询用户
    query(data) {
      return service.blackQuery(data).then(res => {
        if(res.code == "88888"){
          this.$confirm(res.msg, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.queryTwo(data)
          }).catch(() => {

          });
        }else{
          if (res.data !== '') {
            Message.warning(res);
          } else {
            Message.success("查询成功！")
          }
          this.getReports();
          this.init();
          this.$refs.child.clear();
        }

      });
    },

    queryTwo(data) {
      return service.directQuery(data).then(res => {
        if (res.data !== '') {
          Message.warning(res);
        } else {
          Message.success("查询成功！")
        }
        this.getReports();
        this.init();
        this.$refs.child.clear();
      });
    },
    // 搜索列表
    searchList(data) {
      let params = { username: data.name, mobile: data.mobile, idcard: data.idcard };
      this.searchData = params;
      return this.getList({ ...params, pageNum: 1,queryType: this.queryType });
    },
    handleClose() {
      this.setShowView(false);
      this.getList({ pageNum: this.currentPage, pageSize: this.pageSize,queryType: this.queryType });
    },
    openReport(row) {
      if (!row.verify_status.real_name) {
        // 未实名认证不可查看报告
        this.$notify({
          type: "error",
          message: "用户未实名认证，暂不能查看用户报告！",
          offset: 400
        });
        return;
      }
      // 打开报告界面
      const { href } = this.$router.resolve({
        path: `/risk/detail`,
        query: { flId: row.flId,queryType:this.queryType}
      });
      window.open(href, '_blank');
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss" scoped></style>
